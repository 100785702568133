import { GetServerSidePropsContext, PreviewData } from 'next';
import {
   generateChallenge,
   generateCodeVerifier,
   getVerifier,
   removeVerifier,
   storeVerifier,
} from './codeVerifier';

import { AESDecrypt } from './encryption';
import Cookies from 'js-cookie';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import axios from 'axios';
import platform_axios from '~/utils/axios';
import { sentryCaptureException } from '~/errors/sentry';
import { setCookie } from 'cookies-next';

export const _URL = process.env.NEXT_PUBLIC_SSID_PKCE_URL;
export const _SSID_OPENID_ENDPOINT = 'idp/realms/ssid-realm/protocol/openid-connect';
const client_id = process.env.NEXT_PUBLIC_SSID_PKCE_CLIENT_ID;
const redirect_url = process.env.NEXT_PUBLIC_SSID_PKCE_REDIRECT_URL;
const scope = process.env.NEXT_PUBLIC_SSID_PKCE_SCOPE;
const logout_url = process.env.NEXT_PUBLIC_SSID_PKCE_LOGOUT_URL;

export const generateSSIDLoginURL = async (lang: string) => {
   if (typeof window === 'undefined') return '';
   const verifier = getVerifier() || generateCodeVerifier();
   storeVerifier(verifier);
   const challenge = await generateChallenge(verifier);

   return `${_URL}/${_SSID_OPENID_ENDPOINT}/auth?client_id=${client_id}&scope=${scope}&response_type=code&redirect_uri=${redirect_url}&code_challenge=${challenge}&code_verifier=${verifier}&code_challenge_method=S256&ui_locales=${lang}`;
};

export const generateProfileURL = (lang: string) => {
   return `${_URL}/individual/account-settings?ui_locales=${lang}&client_id=${client_id}`;
};

export const getBearerToken = async (ssidToken: string) => {
   const Authorization = `Bearer ${ssidToken}`;
   try {
      const { data } = await platform_axios.post('ssid/get-token', null, {
         headers: { Authorization },
      });
      return { data, error: false };
   } catch (error) {
      sentryCaptureException(error);
      console.log(error.response?.data || error);
      return { error: true, data: null };
   }
};

type StoreBeforeLoginParams = {
   router: NextRouter;
};
export const storeAndSSIDLogin = async ({ router }: StoreBeforeLoginParams) => {
   const { asPath } = router;
   Cookies.set('redirect_url_ssid', asPath);
   window.location.href = await generateSSIDLoginURL(
      (router.query.lang as string).split('-')[1] || 'en'
   );
};

export const ssidLogout = async (ssid_refresh_token: string | undefined) => {
   if (!ssid_refresh_token) {
      removeVerifier();
      window.location.href = logout_url || '/';
      return;
   }
   setCookie('ssid_logout_destination', window.location.href, { maxAge: 24 * 60 * 60 });
   try {
      const response = await axios.post('/api/logout-ssid-session', { ssid_refresh_token });
      if (response.status === 200) {
         removeVerifier();
         window.location.href = logout_url || '/';
      } else {
         throw new Error(
            response?.data?.error || response?.data?.original?.error || 'Something went wrong'
         );
      }
   } catch (error) {
      sentryCaptureException(error);
      console.log(error.response || error);
   }
};

export const handleSSIDLoginViaToken = async (
   ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
   const { token: encrypted_token } = ctx.query;
   console.log('encrypted_token', encrypted_token);
   let token = '';
   if (encrypted_token) token = AESDecrypt(encrypted_token as string);
   if (token.trim()) {
      const { data, error } = await getBearerToken(token as string);
      if (!error) {
         const days = Number(process.env.NEXT_PUBLIC_TOKEN_COOKIES_AGE) || 1;
         const age = days * 24 * 60 * 60;

         setCookie('token', data.access_token, {
            req: ctx.req,
            res: ctx.res,
            maxAge: age,
            path: '/',
         });
         setCookie('ssid_token', token, {
            req: ctx.req,
            res: ctx.res,
            maxAge: age,
            path: '/',
         });
         setCookie('user_id', data._id, {
            req: ctx.req,
            res: ctx.res,
            maxAge: age,
            path: '/',
         });

         return true;
      }
   }
   return false;
};
