import CryptoJS from 'crypto-js';

const key = 'TmF0aW9uYWxOQmFua09mT21hbjYeKhHt';
const iv = '1234567898766492';

// PEM key generated from Static JWK Data of SSID (https://8gwifi.org/jwkconvertfunctions.jsp)
// https://uat-ssid.visitsaudi.com/ssid/.well-known/jwks.json
// https://ssid.visitsaudi.com/ssid/.well-known/jwks.json
// This PEM key is used for PASSWORD ENCRYPTION (ref: pages -> api -> ssid-oauth-login)
export const SSID_PEM_STRING = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhj8zFdhYFi+47PO4B4HT
RuOLPR/rpZJi66g4JoY4gyhb5v3Q57etSU9BnW9QQNoUMDvhCFSwkz0hgY5HqVj0
zOG5s9x2a594UDIinKsm434b+pT6bueYdvM/mIUEKka5pqhy90wTTka42GvM+rBA
THPTarq0kPTR1iBtYao8zX+RWmCbdumEWOkMFUGbBkUcOSJWzoLzN161WdYr2kJU
5PFraUP3hG9fPpMEtvqd6IwEL+MOVx3nqc7zk3D91E6eU7EaOy8nz8echQLl6Ps3
4BSwEpgOhaHDD6IJzetW+KorYeC0r0okXhrl0sUVE2c71vKPVVtueJSIH6OwA3dV
HQIDAQAB
-----END PUBLIC KEY-----`;

/**
 * Encrypts a plaintext string using AES-256-CBC.
 * @param {string} plaintext - The string to encrypt.
 * @returns {string} - The encrypted string in Base64 format.
 */
export const AESEncrypt = (plaintext: string): string => {
   try {
      // Parse the key and IV
      const keyBytes = CryptoJS.enc.Utf8.parse(key);
      const ivBytes = CryptoJS.enc.Utf8.parse(iv);

      // Encrypt the plaintext
      const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
         iv: ivBytes,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7,
      });

      // Return the encrypted data as a Base64 string
      return encrypted.toString();
   } catch (error) {
      console.error('AESEncrypt error:', error);
      throw new Error('Encryption failed');
   }
};

/**
 * Decrypts an encrypted string using AES-256-CBC.
 * @param {string} ciphertext - The encrypted string in Base64 format.
 * @returns {string} - The decrypted plaintext string.
 */

export const AESDecrypt = (ciphertext: string): string => {
   try {
      // Parse the key and IV
      const keyBytes = CryptoJS.enc.Utf8.parse(key);
      const ivBytes = CryptoJS.enc.Utf8.parse(iv);

      // Decrypt the ciphertext
      const decrypted = CryptoJS.AES.decrypt(ciphertext, keyBytes, {
         iv: ivBytes,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7,
      });

      // Convert the decrypted data to a UTF-8 string
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

      // Check if the decrypted text is valid
      if (!decryptedText) {
         throw new Error('Decryption failed or resulted in an empty string');
      }

      return decryptedText;
   } catch (error) {
      console.error('AESDecrypt error:', error);
      return '';
      // throw new Error('Decryption failed');
   }
};

// SHA-256 Hash using crypto-js
export const SHA_Hash = (content: string) => {
   try {
      const hash = CryptoJS.SHA256(content);
      return hash.toString(CryptoJS.enc.Hex);
   } catch (error) {
      console.error('SHA_Hash error:', error);
      return '';
   }
};

// RSA Encryption using crypto-js (Note: crypto-js does not support RSA directly)
// For RSA, you would still need to use Node.js's `crypto` module or a library like `node-rsa`.
export const RSAEncrypt = (content: string, privateKey?: string) => {
   try {
      const publicKey = privateKey || SSID_PEM_STRING;
      const encrypted = CryptoJS.AES.encrypt(content, publicKey); // This is a placeholder
      return encrypted.toString();
   } catch (error) {
      console.error('RSAEncrypt error:', error);
      return '';
   }
};
